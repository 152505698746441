import HorizontalBrands from "components/layout/store/brands/HorizontalBrands";
import {useMemo} from "react";
import {shuffle} from "common/utils";


export const BrandCurationSection = (props) => {
    const {curation, curationIdx, location} = props;

    const brands = useMemo(() => {
        let items = curation.item_list;
        if (curation.max_item_count) {
            items = items.slice(0, curation.max_item_count);
        }
        return items;
    }, [curation]);

    const handleBrandClick = props.onProductClick || (
        (brand, brandIndex) => {
            typeof mixpanel !== 'undefined' && mixpanel.track(
                'BrandCurationSectionBrandClick',
                {
                    location,
                    curationTitle: curation.title,
                    curationDescription: curation.description,
                    brandId: brand.id,
                    brandName: brand.name,
                    brandIndex: brandIndex,
                    curationIdx
                }
            );
        }
    );

    if (curation.display_type === 'HORIZONTAL') {
        return (
            <HorizontalBrands
                brands={brands}
                onBrandClick={handleBrandClick}
                isColorBackground={curation.is_color_brand_logo_used}
            />
        )
    } else {
        return <div/>;
    }
}
