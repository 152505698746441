import styles from "components/layout/palette/GridPosts.module.scss";
import classNames from "classnames";
import SimplePostListCard from "components/cards/SimplePostListCard";


const GridPosts = (props) => {
    const {
        posts, onPostClick, hideText, showInlineProduct
    } = props;

    const column_count = props.columnCount || 3;

    return (
        <div className={classNames(styles.root, styles[`column_${column_count}`], hideText && styles.hideTextRoot)}>
            {
                posts.map((e, index) => {
                    return <SimplePostListCard
                        key={`post-${e.id}`}
                        post={e}
                        onPostClick={() => onPostClick(e, index)}
                        hideText={hideText}
                        showInlineProduct={showInlineProduct}
                    />
                })
            }
        </div>
    )
};

export default GridPosts;
