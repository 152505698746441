import {isProductSoldOut, shuffle} from "common/utils";
import styles from "components/sections/curation/BannerProductCurationSection.module.scss";
import {useContext, useMemo} from "react";
import VerticalProductListCard from "components/cards/VerticalProductListCard";
import classNames from "classnames";
import utilStyles from "styles/utils.module.scss";
import {Swiper, SwiperSlide} from "swiper/react";
import * as PropTypes from "prop-types";
import {Axios} from "../../../api";
import {toast} from "react-toastify";
import {captureException, captureMessage} from "@sentry/nextjs";
import {useRouter} from "next/router";
import UserContext from "context/AuthContext";


export const BannerProductCurationSection = (props) => {
    const {
        curation,
        location,
        isFoldable,
        imageRatio,
        showPromotionDaysLeft,
        isFoldedFromStart,
        isTitleHidden,
        isAddToPouchVisible,
        onPouchAdd,
        useBestCouponDiscount,
        productIdToDescription,
        theme
    } = props;
    const products = useMemo(() => {
        let items = curation.item_list.filter((product) => {
            return !isProductSoldOut(product)
        });
        if (curation.max_item_count) {
            items = items.slice(0, curation.max_item_count);
        }
        return items;
    }, [curation])

    const handleProductClick = props.onProductClick || (
        (product, productIndex) => {
            typeof mixpanel !== 'undefined' && mixpanel.track(
                'BannerProductCurationSectionProductClick',
                {
                    location,
                    curationTitle: curation.title,
                    curationDescription: curation.description,
                    productId: product.id,
                    productName: product.name,
                    productIndex,
                    curationId: curation.id,
                }
            );
        }
    );

    const router = useRouter();
    const userContext = useContext(UserContext);
    const user = userContext.user;
    const bannerImageClick = (link) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('BannerProductCurationImageClick', {
            is_member: !!user,
            curation_id: curation.id,
            curation_title: curation.title,
        });
        router.push(link)
    }
    return (
        <div style={{width: '100%'}}>
            <Swiper
                className={styles.verticalSwiperWrapper}
                loop={curation.mobile_image_list && (curation.mobile_image_list?.length > 1)}
                autoplay={
                    {
                        delay: 4000,
                        disableOnInteraction: false
                    }
                }
                speed={800}
                spaceBetween={0}
                slidesPerView={1}
                threshold={20}
                pagination={{
                    type: 'fraction',
                    horizontalClass: styles.hiddenSwiper,
                    currentClass: styles.hiddenSwiper
                }}
            >
                {
                    curation.mobile_image_list.map((image, idx) => {
                        return (
                            <SwiperSlide key={idx}>
                                <a className={styles.buttonWrapper} onClick={() => bannerImageClick(image.link)}>
                                    <img className={styles.verticalImage} src={image.url} alt={"배너 이미지"}/>
                                </a>

                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
            {/*<img className={styles.verticalImage} src={curation.mobile_image_url} alt={"배너 이미지"}/>*/}
            <div className={styles.overlay}/>
            <div className={classNames(styles.productsWrapper)}>
                {
                    products.map((e, index) => {
                        return <>
                            <VerticalProductListCard
                                onCloseOptionSelectorModal={() => onPouchAdd(e)}
                                key={`product-${e.id}`}
                                product={e}
                                onClick={() => handleProductClick(e, index)}
                                imageRatio={imageRatio}
                                showPromotionDaysLeft={showPromotionDaysLeft}
                                description={productIdToDescription && productIdToDescription[e.id]}
                                hasAddToPouchButton={isAddToPouchVisible}
                                useBestCouponDiscount={useBestCouponDiscount}
                                theme={theme}
                            />
                                {
                                    index !== products.length - 1 &&
                                    <div className={utilStyles.borderLine2}/>
                                }
                        </>
                    })
                }
            </div>
        </div>
    )
}
