import styles from "components/sections/curation/CurationSection.module.scss";
import GridProducts from "components/layout/store/products/GridProducts";


export const EventCurationSection = (props) => {
    const {curation, curationIdx, location} = props;

    const products = curation.item_list;

    const handleProductClick = props.onProductClick || (
        (product, productIndex) => {
            typeof mixpanel !== 'undefined' && mixpanel.track(
                'EventCurationSectionProductClick',
                {
                    location,
                    curationTitle: curation.title,
                    curationDescription: curation.description,
                    productId: product.id,
                    productName: product.name,
                    productIndex: productIndex,
                    curationIdx
                }
            );
        }
    );

    if (curation.display_type === 'ONE_COLUMN') {
        return (
            <div className={styles.gridContainer}>
                <GridProducts
                    products={products}
                    onProductClick={handleProductClick}
                    columnCount={1}
                    imageRatio="50%"
                    showPromotionDaysLeft
                    productIdToDescription={curation.product_id_to_description}
                />
            </div>
        )
    } else {
        return <div/>;
    }
}
