import styles from "components/sections/curation/CurationSection.module.scss";
import HorizontalPosts from "components/layout/palette/HorizontalPosts";
import GridPosts from "components/layout/palette/GridPosts";
import {useMemo} from "react";
import {shuffle} from "common/utils";


export const PostCurationSection = (props) => {
    const {curation, curationIdx, location} = props;

    const posts = useMemo(() => {
        let items = curation.item_list;
        if (curation.max_item_count) {
            items = items.slice(0, curation.max_item_count);
        }
        items.forEach((item, idx) => {
            item.media = item.media_list ? item.media_list[0] : null;
        })
        return items;
    }, [curation]);

    const handlePostClick = props.onPostClick || (
        (post, postIndex) => {
            typeof mixpanel !== 'undefined' && mixpanel.track(
                'PostCurationSectionPostClick',
                {
                    location,
                    curationTitle: curation.title,
                    curationDescription: curation.description,
                    postId: post.id,
                    postIndex,
                    curationIdx
                }
            );
        }
    );

    const content = useMemo(() => {
        if (curation.display_type === 'HORIZONTAL') {
            return (
                <HorizontalPosts
                    posts={posts}
                    onPostClick={handlePostClick}
                    showText={curation.is_post_description_visible}
                    largeIcon
                    showInlineProduct
                />
            )
        } else if (curation.display_type.includes('COLUMN')) {
            const column_count_str = curation.display_type.split('_')[0];
            const column_count = column_count_str === 'ONE' ? 1 : column_count_str === 'TWO' ? 2 : 3;
            return (
                <div className={styles.gridContainer}>
                    <GridPosts
                        posts={posts}
                        onPostClick={handlePostClick}
                        columnCount={column_count}
                        hideText={!curation.is_post_description_visible}
                        showInlineProduct
                        // hideTextAndProduct={!curation.is_post_description_visible}
                    />
                </div>
            )
        } else {
            return <div/>;
        }
    }, [curation]);

    return (
        <>
            {content}
        </>
    )
}
