import styles from 'components/buttons/RoundTextButton.module.scss';
import classNames from "classnames";

export const RoundTextButton = (props) => {
    const {text, handleClick, isSelected, marginRight, theme} = props;

    const selectedTheme = theme || 'default';

    return (
        <div className={styles[`theme-${selectedTheme}`]}>
            <button className={classNames(styles.container, isSelected ? styles.selected : styles.unSelected)} onClick={handleClick} style={{marginRight : marginRight}}>
                <span>{text}</span>
            </button>
        </div>
    )
};
